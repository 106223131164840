import React from 'react';
import { useHomeAssistantWebSocket } from '../useHomeAssistantWebSocket';
import { Link } from "react-router-dom";
import BtnXHome from '../components/BtnXHome'; // Adjust path based on your file structure
const apiKey = process.env.REACT_APP_API_KEY_HOMEASSISTANT;


const TestWebsock  = () => {
  const homeAssistantUrl = 'wss://f1sd582v2u3wdospyl0cae1f1bjt2o05.ui.nabu.casa/api/websocket';  // Nabu Casa WebSocket URL
  const authToken = {apiKey};  // Replace with your actual token
  const { connectionStatus, sensorState, connection } = useHomeAssistantWebSocket(homeAssistantUrl, authToken);
  const [lightState, setLightState] = React.useState('off');

  const turnOnLight = () => {
    if (connection && lightState === 'off') {
      connection.send(JSON.stringify({
        id: 3,
        type: 'call_service',
        domain: 'light',
        service: 'turn_on',
        service_data: {
          entity_id: 'light.testlicht'
        }
      }));
      setLightState('on');
    }
  };

  const turnOffLight = () => {
    if (connection && lightState === 'on') {
      connection.send(JSON.stringify({
        id: 4,
        type: 'call_service',
        domain: 'light',
        service: 'turn_off',
        service_data: {
          entity_id: 'light.testlicht'
        }
      }));
      setLightState('off');
    }
  };

  const triggerWebhook1 = () => {
    fetch('https://hooks.nabu.casa/gAAAAABm9Z_NdVqdPGjIclPk4QKXrR_j4nl3QIJ_Qjs-HecOLH7n8eoakI076AVCkHtnd1vwxoXgoILdoiZwvEMHEmcAeIwnryF--dP0SUAIs06Vft6jXyvCgvFzKh57IphrLZDHMdMLdHXlZCNVf68WZelDSlJxQVz4UY7uCkFaT0ZXg4gE8NQ=', {
      method: 'POST',
      mode: 'no-cors'
    })
    .then(() => {
      console.log('Webhook triggered successfully');
    })
    .catch(error => {
      console.error('Error triggering webhook:', error);
    });
  };


  const triggerWebhook2 = () => {
    fetch('https://hooks.nabu.casa/gAAAAABm_naGt12MQvzG66b_TEy3Le5wL2xbtwoTvvk2YKRvIc3yfGKIlEKBwtwCxWLMRzyc_UDCLOrdIUWzvPD65oZeJS0i7L4txD6TpvF596xBxhR8l01boT3dwXrhGBbqQH7u81x-q-0j-Y7Fs5UJ1vCmqkFo9ylkqUUW5QUONrdMgMx3Nj0=', {
      method: 'POST',
      mode: 'no-cors'
    })
    .then(() => {
      console.log('Webhook triggered successfully');
    })
    .catch(error => {
      console.error('Error triggering webhook:', error);
    });
  };

  const divStyle = {
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    backgroundColor: sensorState === 'on' ? 'red' : 'green',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px auto',
    color: '#fff',
    fontSize: '18px',
    fontWeight: 'bold',
  };

  return (
    <>
   <BtnXHome />
      <h1>Home Assistant WebSocket Demo</h1>
      <Link to="/">Home</Link>
      <h2>Connection Status: {connectionStatus}</h2>

      <div className="button-group">
        <button className="control-button" onClick={turnOnLight}>Turn On Light</button>
        <button className="control-button" onClick={turnOffLight}>Turn Off Light</button>
        <button className="control-button" onClick={triggerWebhook1}>Trigger Webhook</button>
        <button className="control-button" onClick={triggerWebhook2}>Trigger Light</button>
      </div>

      <h2>Door Sensor Status: {sensorState || 'Unknown'}</h2>
      <div style={divStyle}>
        {sensorState === 'on' ? 'Open' : 'Closed'}
      </div>
    </>
  );
};

export default TestWebsock;
