import React from "react";
import { mk_red, nk_blue, getHealthBarGradient, getHealthBarColor } from '../../styles/Sc2_color';

const Sc2TugOfBattle = ({
  mousekingHealth,
  dispheight,
  nutcrackerHealth,
  selectedTeam,
}) => {
  // Calculate the total health and relative percentages
  const totalHealth = mousekingHealth + nutcrackerHealth;
  const mousekingHealthPercent =
    totalHealth === 0 ? 50 : (mousekingHealth / totalHealth) * 100;
  const nutcrackerHealthPercent =
    totalHealth === 0 ? 50 : (nutcrackerHealth / totalHealth) * 100;

  // Bar components
  const mousekingBar = (
    <div
      className="Sc2TugOfWar_healthbar"
      style={{
        width: `${mousekingHealthPercent}%`,
        background: getHealthBarGradient("Mouseking"), // Use gradient
        borderRight: selectedTeam === "Nutcracker" ? "1px solid grey" : "none",
        borderLeft: selectedTeam === "Mouseking" ? "1px solid grey" : "none",
        position: "relative",
      }}
    >
      {/* Mouseking Text */}
      <span
        style={{
          position: "absolute",
          left: selectedTeam === "Mouseking" ? "15px" : "auto",
          right: selectedTeam === "Nutcracker" ? "15px" : "auto",
          bottom: "15px",
          whiteSpace: "nowrap",
          color: "#d4d4d4",
          fontWeight: "bold",
          fontSize: "1rem",
          zIndex: 2,
          textAlign: selectedTeam === "Mouseking" ? "left" : "right",
        }}
      >
        <p
          className="Sc2TugOfWar_healthbar_text"
          style={{ color: getHealthBarColor("Mouseking") }}
        >
          {Math.round(mousekingHealthPercent)}% Mouseking
        </p>
      </span>
    </div>
  );

  const nutcrackerBar = (
    <div
      className="Sc2TugOfWar_healthbar"
      style={{
        width: `${nutcrackerHealthPercent}%`,
        background: getHealthBarGradient("Nutcracker"), // Use gradient
        borderRight: selectedTeam === "Mouseking" ? "1px solid grey" : "none",
        borderLeft: selectedTeam === "Nutcracker" ? "1px solid grey" : "none",
        position: "relative",
      }}
    >
      {/* Nutcracker Text */}
      <span
        style={{
          position: "absolute",
          left: selectedTeam === "Nutcracker" ? "15px" : "auto",
          right: selectedTeam === "Mouseking" ? "15px" : "auto",
          bottom: "15px",
          whiteSpace: "nowrap",
          color: "#d4d4d4",
          fontWeight: "bold",
          fontSize: "1rem",
          zIndex: 2,
          textAlign: selectedTeam === "Nutcracker" ? "left" : "right",
        }}
      >
        <p
          className="Sc2TugOfWar_healthbar_text"
          style={{ color: getHealthBarColor("Nutcracker") }}
        >
          {Math.round(nutcrackerHealthPercent)}% Nutcracker
        </p>
      </span>
    </div>
  );

  return (
    <div style={{ width: "100%", margin: "0px auto" }}>
      <div
        style={{
          width: "100%",
          height: dispheight,
          backgroundColor: "#e0e0df",
          borderRadius: "0px",
          marginTop: "-20px",
          overflow: "hidden",
          borderBottom: "1px solid black",
          display: "flex",
          position: "relative",
        }}
      >
        {/* Dynamically render bars based on selectedTeam */}
        {selectedTeam === "Mouseking" ? (
          <>
            {mousekingBar}
            {nutcrackerBar}
          </>
        ) : (
          <>
            {nutcrackerBar}
            {mousekingBar}
          </>
        )}
      </div>
    </div>
  );
};

export default Sc2TugOfBattle;
