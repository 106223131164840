import React, { useRef, useState, useEffect } from "react";
import { useHomeAssistantWebSocket } from "../../useHomeAssistantWebSocket";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col } from "react-bootstrap";
import BtnXHome from "../../components/BtnXHome";

import Sc2PostContentMK from "../../components/Sc2/Sc2_PostContent_MK";
import Sc2PostContentNC from "../../components/Sc2/Sc2_PostContent_NC";
import SharePostButton from "../../components/Sc2/Sc2_sharePostButton";

import { FaCheese, FaSkullCrossbones } from "react-icons/fa";
import Sc2teamcircle from "../../components/Sc2/sc2_team_circle";
import Tooltipbutton from "../../components/tooltipButton";
import Sc2TugOfBattle from "../../components/Sc2/sc2_tugofbattle";

import { useStats } from "../../functions/Sc2_homeAssistantStats";
import { getWarStatFeedback } from "../../functions/Sc2_warStat";
import { getMousekingMessage } from "../../components/Sc2/sc2_util-mouseking"; // Adjust the path as needed
import Sc2TugOfWar from "../../components/Sc2/sc2_tugofwar";

import { mk_red, nk_blue } from '../../styles/Sc2_color';

const teamnamered = []; // Define the red color variable
const ActiveTeam="Nutcracker"

const getGradientColor = (percentage, invert = false) => {
  let colors;
  if (percentage <= 20) {
    // Losing - Slight Red tones
    colors = "#3b3b3b, #881b1b"; // Dark Red touch
  } else if (percentage <= 50) {
    // Struggling - Transition to Yellow with a hint of Red
    colors = "#3b3b3b, #3b2b1e"; // Subtle Red-Orange
  } else if (percentage <= 80) {
    // Balanced - Yellow tones with a faint Green tint
    colors = "#3b3b3b, #2e3b2e"; // Subtle Green-Gray
  } else {
    // Winning - Slight Green tones
    colors = "#3b3b3b, #468b46"; // Dark Green touch
  }

  // If invert is true, reverse the order of colors
  if (invert) {
    const [startColor, endColor] = colors.split(", ");
    colors = `${endColor}, ${startColor}`;
  }

  return colors;
};
const Sc2MouseStat = () => {
  const stats = useStats(); // Get the full stats object

  // Calculate percentage controlled by Mouse King
  const totalHealth = stats.mousekingHealth + stats.nutcrackerHealth;
  const nutcrackerHealthPercent =
    totalHealth === 0 ? 50 : (stats.nutcrackerHealth / totalHealth) * 100;
    const mousekingHealthPercent =
    totalHealth === 0 ? 50 : (stats.mousekingHealth / totalHealth) * 100;

  // Messages for the Mouse King
  const nutcracker = getMousekingMessage(nutcrackerHealthPercent);
  const postRef = useRef(null);
  const {
    LosingWarStatNutcracker,
    LosingWarStatMouseking,
    nutcrackerWarMessage,
    mousekingWarMessage, // Feedback message from getWarStatFeedback
  } = getWarStatFeedback(stats.WarStatNutcracker, stats.WarStatMouseking);

  return (
    <>
      {/* <BtnXHome /> */}
      <div
        style={{
          position: "relative",
          width: "100%",
          textAlign: "center",
          // border:'10px solid blue'
        }}

      >
         
        <div>
          
          <div
            className="Sc2bg-mouseking"
            style={{
              backgroundImage: "url(../images/misc/generic_background.png)",
              backgroundColor: "rgba(0, 0, 0, .5)", // Semi-transparent black background
              backgroundBlendMode: "overlay", // Ensures blend between color and image
            }}
          >
           <SharePostButton
        team={ActiveTeam} // Dynamic team selection
        contentRef={postRef}
        title="The Nutcracker Army"
        text="Check out the latest stats for The Mouse King Army!"
      />
            <div
              className="Sc2bg-nutcracker-overlay"
              // style={{boxShadow: '0px 0px 250px 200px  rgba(182, 31, 49, 0.8)'}}
            />
            <img
              src="../images/nutcracker/nutcracker_single.png"
              alt="Mouseking"
              style={{
                maxWidth: "100%",
                minHeight: "300px",
                zIndex: "60",
                marginTop: "-50px",
                padding: "0px",
                margin: "auto",
                position: "relative",
                // display:'block'
              }}
            />
          </div>
        </div>

        <div
          className={`Sc2teamname Sc2tn-nc ${
            stats.nutcrackerHealthChanged ? "health-changed" : ""
          }`}
          style={{
            backgroundColor: "white",
            // borderBottom: "1px solid black",
            // borderRadius: "10px",
            background: `linear-gradient(120deg, #161822 20%, ${nk_blue} 100%)`,
          }}
        >
         
          <p class="gradient-text">The {ActiveTeam} Army</p>
          
        </div>
      </div>

      <Row style={{ width: "100%" }}>
        <div
          className={`Sc2h2 ${
            stats.nutcrackerHealthChanged ? "health-changed" : ""
          }`}
        >
          Ongoing Battle Information
        </div>
      </Row>
      <Sc2TugOfBattle
        mousekingHealth={stats.mousekingHealth}
        nutcrackerHealth={stats.nutcrackerHealth}
        selectedTeam={ActiveTeam}
        dispheight="230px"
      />

      <Row
        className="stat-card-message"
        style={{
          width: "100%",
          background: `linear-gradient(145deg, ${getGradientColor(
            nutcrackerHealthPercent,
            true // Invert the gradient
          )})`,
          position: "relative",
          paddingTop: "15px",
          paddingBottom: "15px",
        }}
      >
        <div
          style={{
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "2px",
            background: `linear-gradient(145deg, ${getGradientColor(
              nutcrackerHealthPercent
            )})`,
            zIndex: 1,
          }}
        />
        <Row>
          <Col style={{ padding: "20px" }}>
            <strong>Battle Advisor:</strong>
            <br />
            {nutcrackerWarMessage}
          </Col>
        </Row>
      </Row>
      <Row
        className="w-100 m-0 d-flex align-items-stretch"
        style={{ borderTop: "2px solid black" }}
      >
        {/* <Col className="stat-card">
      {stats.WarStatMouseking}
      </Col> */}
      </Row>

      <Row style={{ width: "100%" }}>
        <div
          className={`Sc2h2 ${
            stats.nutcrackerHealthChanged ? "health-changed" : ""
          }`}
        >
          War Statistic
        </div>
        <Col className="stat-card-light">
          <p>
            <strong>{nutcrackerWarMessage}</strong>
            <br />
            {!LosingWarStatNutcracker && (
              <>We are currently ahead by <strong>{stats.WarStatNutcracker}</strong> Battles!</>
            )}
            {LosingWarStatNutcracker && (
              <strong>
                We are behind by {Math.abs(stats.WarStatNutcracker)} Battles!
              </strong>
            )}
          </p>
          
        </Col>
        
      </Row>

      <div style={{width:'100%', margin:'0px', paddingBottom:'20px'}}>
        <Col>
        <Sc2TugOfWar
          battlesWonMouseking={stats.battlesWonMouseking}
          battlesWonNutcracker={stats.battlesWonNutcracker}
          mousekingHealth={stats.mousekingHealth}
          nutcrackerHealth={stats.nutcrackerHealth}
          selectedTeam={ActiveTeam}
          stats={stats}
          dispheight='180px'
        />
        </Col>
      </div>
      

      <Row style={{ width: "100%" }}>
        <div
          className={`Sc2h2 ${
            stats.nutcrackerHealthChanged ? "health-changed" : ""
          }`}
        >
          More Information
        </div>
      </Row>

      <Row
        className="w-100 m-0 d-flex align-items-stretch"
        style={{ borderTop: "2px solid black" }}
      >
        <Col className="stat-card-light-list">
          {[
            { value: stats.battlesWonMouseking, label: "Battles Lost" },
            { value: stats.battlesWonNutcracker, label: "Battles won" },
            { value: stats.damageDealtToNutcracker, label: "Damage received" },
            { value: stats.damageDealtToMouseking, label: "Damage dealt" },
            // {
            //   value: stats.HealthPotionFedNutcracker,
            //   label: "Health Potions swallowed",
            // },
            // {
            //   value: stats.HealthPotionFedMouseking,
            //   label: "Health Potions Nutcracker",
            // },
          ].map((stat, index) => (
            <div key={index} className="stat-list">
              <Row>
                <Col><span className="nr">{stat.value}</span></Col>
                <Col><span> {stat.label}</span></Col>
              </Row>
              
              
            </div>
          ))}
        </Col>
      </Row>

      <div
        style={{
          position: "fixed",
          top: "100%",
          left: "0",
          opacity: 0,
          pointerEvents: "none", // Prevents user interaction
          zIndex: -100, // Ensures it's below everything else
        }}
      >
        <Sc2PostContentNC
          stats={stats}
          ref={postRef}
          ActiveTeam="Nutcracker"
        />
      </div>
    </>
  );
};

export default Sc2MouseStat;
