import React from 'react';
import './style.css';
import { Row, Col } from 'react-bootstrap';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { StatsProvider } from "./functions/Sc2_homeAssistantStats";
import Sc2TeamSelection from './pages/scene2/Sc2_team_selection';
import Sc2HealthBar from './pages/scene2/Sc2_healthbar';

import BinarySensorReset from './components/Sc2/sc2_binaryreset';
import Sc2MouseStat from './pages/scene2/Sc2_mouse_stat';
import Sc2NutcrackerStat from './pages/scene2/Sc2_nutcracker_stat';
import Sc2InstaPostMK from './pages/scene2/Sc2_instaPost_MK';
import Sc2InstaPostNC from './pages/scene2/Sc2_instaPost_NC';
import Sc2BattleScreen from './pages/scene2/Sc2_BattleScreen';

import TestWebsock from './pages/test_websock';
import Sc2MouseStatMobile from './pages/scene2/Sc2_mouse_stat_mobile';
// const homeAssistantUrlBinary =
// "https://f1sd582v2u3wdospyl0cae1f1bjt2o05.ui.nabu.casa/"
// "https://jlxtabtr5ak0ay8irjyejy6n2opayfbi.ui.nabu.casa/api/states/binary_sensor.sc2_cam3_motion";
const homeAssistantUrl =
// "wss://f1sd582v2u3wdospyl0cae1f1bjt2o05.ui.nabu.casa/api/websocket"
"wss://jlxtabtr5ak0ay8irjyejy6n2opayfbi.ui.nabu.casa/api/websocket";
const authToken =
"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJmODYxMzQxNzAwZjI0ZTQyODUxOTIyNWIyYjFkMmM1ZiIsImlhdCI6MTczMjUyNTUwOCwiZXhwIjoyMDQ3ODg1NTA4fQ.Wl-8v-Sj8Ya4iWPKRlMuEysj9WAUqdb8N7zFs4oT_0g"; // Replace with your actual token
// "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJlNWRjYWVhNjQwN2M0N2VjYTQxNjdlZjZkYjhjYWRjZSIsImlhdCI6MTczMzE0OTQwMywiZXhwIjoyMDQ4NTA5NDAzfQ.FLDD5E80FOjW9B-6EJ1STRDyK38NKsm_cfj6sNHVx8s"
function App() {
  return (
    <StatsProvider homeAssistantUrl={homeAssistantUrl} authToken={authToken}>
    <div className="App">
    {/* <BinarySensorReset homeAssistantUrl={homeAssistantUrlBinary} authToken={authToken} /> */}
      <Routes>
        <Route path="/" element={
          <div>
            <div style={{width:'100%', textAlign:'center'}}>
            <h1>COIL Project</h1>
            <h2>The Nutcracker</h2>
            </div>
            
            <ul className='nav'>
            <li class="header">Mouse Stats</li>
            <div class="links">
              {/* <li><Link to="/sc2-team-selection">SC2 Team Selection</Link></li> */}
              {/* <li><Link to="/Sc2Healthbar">SC2 - Health Bar</Link></li> */}
              <li><Link to="/Sc2MouseStat">SC2 - Mouse Stat</Link></li>
              <li><Link to="/Sc2NutcrackerStat">SC2 - Nutcracker Stat</Link></li>         
              </div>
            </ul>

            <ul className='nav'>
            <li class="header">Sharing</li>
              <div class="links">
              <li><Link to="/Sc2InstaPostMK">SC2 - Insta Stat</Link></li>
              <li><Link to="/Sc2InstaPostNC">SC2 - Insta Stat Nutcracker</Link></li>
              <li><Link to="/Sc2BattleScreen">SC2 - Battle Scene</Link></li>
              </div>
            </ul>

            <ul className='nav'>
            <li class="header">Battle Scene / in development</li>
              <div class="links">
              <li><Link to="/Sc2BattleScreen">SC2 - Battle Scene</Link></li>
              </div>
            </ul>
          </div>
        } />
        <Route path="/Sc2BattleScreen" element={<Sc2BattleScreen />} />
        <Route path="/Sc2InstaPostNC" element={<Sc2InstaPostNC />} />
        <Route path="/Sc2InstaPostMK" element={<Sc2InstaPostMK />} />
        <Route path="/Sc2MouseStatMobile" element={<Sc2MouseStatMobile />} />
        <Route path="/test_websock" element={<TestWebsock />} />
        <Route path="/sc2-team-selection" element={<Sc2TeamSelection />} />
        <Route path="/Sc2HealthBar" element={<Sc2HealthBar />} />
        <Route path="/Sc2MouseStat" element={<Sc2MouseStat />} />
        <Route path="/Sc2NutcrackerStat" element={<Sc2NutcrackerStat />} />
      </Routes>
    </div>
    </StatsProvider>
  );
}

export default App;
