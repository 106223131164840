import React from "react";
import { mk_red, nk_blue, getHealthBarGradient, getHealthBarColor } from '../../styles/Sc2_color';
import { getWarStatFeedback } from "../../functions/Sc2_warStat";

const Sc2TugOfWar = ({
  battleScreen,
  fontsize,
  stats,
  warStatNutcracker,
  dispheight,
  battlesWonMouseking,
  battlesWonNutcracker,
  warStatMouseking,
  selectedTeam,
}) => {
  // Get feedback and stats
  const {
    nutcrackerWarMessage,
    mousekingWarMessage,
  } = getWarStatFeedback(warStatNutcracker, warStatMouseking);

  const LosingWarStatMouseking = stats.WarStatMouseking < 0;
const LosingWarStatNutcracker = stats.WarStatNutcracker < 0;

  // Calculate relative percentages for display
  const totalDifference = battlesWonMouseking + battlesWonNutcracker;
  const nutcrackerPercent =
    totalDifference === 0 ? 50 : (battlesWonNutcracker / totalDifference) * 100;
  const mousekingPercent =
    totalDifference === 0 ? 50 : (battlesWonMouseking / totalDifference) * 100;

    console.log("Stats:", stats);
console.log("warStatMouseking:", stats.warStatMouseking);
  // Bar components
  const mousekingBar = (
    <div
      className="Sc2TugOfWar_healthbar"
      style={{
        width: `${mousekingPercent}%`,
        background: getHealthBarGradient("Mouseking"),
        borderRight: selectedTeam === "Nutcracker" ? "1px solid grey" : "none",
        borderLeft: selectedTeam === "Mouseking" ? "1px solid grey" : "none",
        position: "relative",
      }}
    >
      <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: "url(../images/misc/battle.png)", // Overlay image
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      opacity: 0.1, // Control transparency
      pointerEvents: "none", // Make sure the overlay doesn't block interactions
    }}
  >
</div>
      <span
        style={{
          position: "absolute",
          left: selectedTeam === "Mouseking" ? "15px" : "auto",
          right: selectedTeam === "Nutcracker" ? "15px" : "auto",
          bottom: "15px",
          whiteSpace: "nowrap",
          color: "#d4d4d4",
          fontWeight: "bold",
          fontSize: "1rem",
          zIndex: 2,
          textAlign: selectedTeam === "Mouseking" ? "left" : "right",
        }}
      >
        <p
          className="Sc2TugOfWar_healthbar_text"
          style={{ color: getHealthBarColor("Mouseking") }}
        >
          <span style={{fontSize:fontsize}}>
          {Math.round(mousekingPercent)}% 
          </span>/ Mouseking
          <br></br>
        
          {!LosingWarStatMouseking ? (
  battleScreen ? (
    <>The Mouseking is currently ahead by <strong>{stats.WarStatMouseking}</strong> Battles!</>
  ) : (
    <>We are currently ahead by <strong>{stats.WarStatMouseking}</strong> Battles!</>
  )
) : (
  battleScreen ? (
    <>The Mouseking is behind by <strong>{Math.abs(stats.WarStatMouseking)}</strong> Battles!</>
  ) : (
    <>We are behind by <strong>{Math.abs(stats.WarStatMouseking)}</strong> Battles!</>
  )
)}

        </p>
      </span>
    </div>
  );

  const nutcrackerBar = (
    <div
  className="Sc2TugOfWar_healthbar"
  style={{
    position: "relative",
    width: `${nutcrackerPercent}%`,
    background: getHealthBarGradient("Nutcracker"), // Gradient as the base layer
    borderRight: selectedTeam === "Mouseking" ? "1px solid grey" : "none",
    borderLeft: selectedTeam === "Nutcracker" ? "1px solid grey" : "none",
  }}
>
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: "url(../images/misc/battle.png)", // Overlay image
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      opacity: 0.1, // Control transparency
      pointerEvents: "none", // Make sure the overlay doesn't block interactions
    }}
  >
</div>

      <span
        style={{
          position: "absolute",
          left: selectedTeam === "Nutcracker" ? "15px" : "auto",
          right: selectedTeam === "Mouseking" ? "15px" : "auto",
          bottom: "15px",
          whiteSpace: "nowrap",
          color: "#d4d4d4",
          fontWeight: "bold",
          fontSize: "1rem",
          zIndex: 2,
          textAlign: selectedTeam === "Nutcracker" ? "left" : "right",
        }}
      >
  
        <p
          className="Sc2TugOfWar_healthbar_text"
          style={{ color: getHealthBarColor("Nutcracker") }}
        >
          <span style={{fontSize:fontsize}}>  
            {Math.round(nutcrackerPercent)}%</span>/ Nutcracker
            
          <br></br>
          
          {!LosingWarStatMouseking ? (
    <>The Nutcracker is losing by <strong>{Math.abs(stats.WarStatMouseking)}</strong> Battles!</>
  ) : (
    <>The Nutcracker is winning by <strong>{stats.WarStatMouseking}</strong> Battles!</>
  )}

         
        </p>
      </span>
    </div>
  );

  return (
    <div style={{ width: "100%", margin: "0px auto" }}>
      <div
        style={{
          width: "100%",
          height: dispheight,
          backgroundColor: "#e0e0df",
          borderRadius: "0px",
          marginTop: "-20px",
          overflow: "hidden",
          borderBottom: "1px solid black",
          display: "flex",
          position: "relative",
        }}
      >
        {/* Dynamically render bars based on selectedTeam */}
        {selectedTeam === "Mouseking" ? (
          <>
            {mousekingBar}
            {nutcrackerBar}
          </>
        ) : (
          <>
            {nutcrackerBar}
            {mousekingBar}
          </>
        )}
      </div>
    </div>
  );
};

export default Sc2TugOfWar;
