import React from "react";
import domtoimage from "dom-to-image-more";
import { FaShareAlt } from "react-icons/fa"; // Importing a share icon
import { mk_red, nk_blue, getHealthBarGradient } from "../../styles/Sc2_color"; // Import your constants here

const SharePostButton = ({ contentRef, title = "Share Post", text, team = "Nutcracker" }) => {
  const sharePost = async () => {
    if (!navigator.canShare) {
      alert("Sharing is not supported on this browser.");
      return;
    }

    try {
      // Capture the DOM element as a PNG image
      const dataUrl = await domtoimage.toBlob(contentRef.current, {
        width: 1000, // Desired width in pixels
        height: 1000, // Desired height in pixels
        style: {
          transform: "scale(1)", // Ensure proper scaling
          transformOrigin: "top left", // Set origin for scaling
          width: "1000px",
          height: "1000px",
        },
        quality: 1, // Maximum quality
        bgcolor: null, // Preserve transparency
      });

      // Convert the Blob into a File
      const file = new File([dataUrl], "shared_post.png", { type: "image/png" });

      // Use the Web Share API to share the file
      await navigator.share({
        title,
        text: text || "Check out this amazing content!",
        files: [file],
      });
    } catch (error) {
      console.error("Error sharing the post:", error);
    }
  };

  // Define team-specific button and icon styles
  const buttonStyle = {
    background: getHealthBarGradient(team), // Dynamic gradient based on team
    color: "#FFFFFF", // Text color
    border: "none", // Remove default border
    borderRadius: "4px", // Rounded corners
    padding: "5px 10px", // Button padding
    float: "right",
    display: "flex", // Align icon and text
    alignItems: "center",
    marginLeft: "10px",
    gap: "8px", // Space between icon and text
    cursor: "pointer", // Pointer cursor
    fontSize: "1rem", // Button text size
    transition: "all 0.3s ease", // Smooth hover effect
  };

  const hoverStyle = {
    transform: "scale(1.05)", // Slightly enlarge the button
    boxShadow: `0px 4px 15px ${team === "Mouseking" ? mk_red : nk_blue}`, // Glowing shadow based on team
    filter: "brightness(0.9)", // Slightly darken the button
  };

  const iconStyle = {
    color: team === "Mouseking" ? mk_red : nk_blue, // Dynamic color based on team
    fontSize: "1.2rem", // Icon size
    transition: "color 0.3s ease", // Smooth color transition
  };

  return (
    <button
      onClick={sharePost}
      style={buttonStyle}
      onMouseEnter={(e) => {
        e.target.style.transform = hoverStyle.transform;
        e.target.style.boxShadow = hoverStyle.boxShadow;
        e.target.style.filter = hoverStyle.filter;
      }}
      onMouseLeave={(e) => {
        e.target.style.transform = "none";
        e.target.style.boxShadow = "none";
        e.target.style.filter = "brightness(1)";
      }}
    >
      <FaShareAlt style={iconStyle} /> {/* Icon with dynamic styling */}
      Share
    </button>
  );
};

export default SharePostButton;
