import "../../style.css";
import BtnXHome from "../../components/BtnXHome";

const Sc2TeamSelection = () => {
  const nutcrackerImage =
    process.env.PUBLIC_URL + "/images/sc2_nutcracker.webp";
  const mousekingImage = process.env.PUBLIC_URL + "/images/sc2_mouseking.webp";

  return (
    <div>
      <BtnXHome />
      <h1>Team Selection</h1>
      <div className="sc2_wrapper">
          {/* Snowflakes animation */}
      <div className="snowflakes">
        <div className="snowflake"></div>
        <div className="snowflake"></div>
        <div className="snowflake"></div>
        <div className="snowflake"></div>
        <div className="snowflake"></div>
      </div>
        <div className="team_wrapper">
          <div className="team_nut_wrapper">
            <h2>Team Nutcracker</h2>
            <div
              className="team_nut"
              style={{ backgroundImage: `url(${nutcrackerImage})` }}
            ></div>
          </div>

          <div className="team_mouse_wrapper">
            <h2>Team Mouseking</h2>
            <div
              className="team_mouse"
              style={{ backgroundImage: `url(${mousekingImage})` }}
            ></div>
          </div>
        </div>
      </div>

    
    </div>
  );
};

export default Sc2TeamSelection;
