import React, { useState, useEffect } from 'react';
import { useHomeAssistantWebSocket } from '../../useHomeAssistantWebSocket';
import { Link } from "react-router-dom";
import BtnXHome from '../../components/BtnXHome'; // Adjust path based on your file structure


  const Sc2HealthBar = () => {
    const homeAssistantUrl = 'wss://f1sd582v2u3wdospyl0cae1f1bjt2o05.ui.nabu.casa/api/websocket';  // Nabu Casa WebSocket URL
    const authToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJkYjY3YTMwMGMzNTU0MWRlYWY2ZTM1Nzg4M2NjOWEwZSIsImlhdCI6MTcyNzI3NTI2MSwiZXhwIjoyMDQyNjM1MjYxfQ.HhLfkuw0s0UwQC4SfGHN4XJjunyFdVF_SnTeL0c3YAk';  // Replace with your actual token
    const { connectionStatus, sensorState, connection } = useHomeAssistantWebSocket(homeAssistantUrl, authToken);
    
    const [nutcrackerHealth, setNutcrackerHealth] = useState(0);
    const [mousekingHealth, setMousekingHealth] = useState(0);
  
    // Setup WebSocket event listener for state changes
    useEffect(() => {
      if (connection) {
        // Listen for state change events
        connection.onmessage = (event) => {
          const message = JSON.parse(event.data);
          
          // Handle state change event for Nutcracker
          if (message.type === 'event' && message.event && message.event.data) {
            const entityId = message.event.data.entity_id;
            if (entityId === 'counter.canada_sc2_healthbar_nutcracker') {
              setNutcrackerHealth(message.event.data.new_state.state);
            } else if (entityId === 'counter.canada_sc2_healthbar_mouseking') {
              setMousekingHealth(message.event.data.new_state.state);
            }
          }
  
          // Initial fetch of all states to get the current health bar values
          if (message.id === 5 && message.result) {
            const nutcracker = message.result.find(item => item.entity_id === 'counter.canada_sc2_healthbar_nutcracker');
            if (nutcracker) {
              setNutcrackerHealth(nutcracker.state);
            }
            const mouseking = message.result.find(item => item.entity_id === 'counter.canada_sc2_healthbar_mouseking');
            if (mouseking) {
              setMousekingHealth(mouseking.state);
            }
          }
        };
  
        // Request initial states when the connection is established
        connection.send(JSON.stringify({
          id: 5,
          type: 'get_states'
        }));
  
        // Subscribe to all state changes
        connection.send(JSON.stringify({
          id: 6,
          type: 'subscribe_events',
          event_type: 'state_changed'
        }));
      }
    }, [connection]);
  
    // CSS for the health bars
    const healthBarStyle = {
      width: '100%',
      backgroundColor: '#e0e0df',
      borderRadius: '5px',
      overflow: 'hidden',
      margin: '20px 0'
    };
  
    const getHealthBarFillStyle = (value) => ({
      height: '30px',
      width: `${value}%`,  // Set the width based on the health bar value
      backgroundColor: '#76c7c0',
      textAlign: 'center',
      lineHeight: '30px',
      color: 'white'
    });
  
    return (
      <>
        <BtnXHome />
        <h1>Home Assistant WebSocket Demo</h1>
        <Link to="/">Home</Link>
        <h2>Connection Status: {connectionStatus}</h2>
  
        {/* Nutcracker Health Bar */}
        <h2>Nutcracker Health Bar:</h2>
        <div style={healthBarStyle}>
          <div style={getHealthBarFillStyle(nutcrackerHealth)}>{nutcrackerHealth}%</div>
        </div>
  
        {/* Mouseking Health Bar */}
        <h2>Mouseking Health Bar:</h2>
        <div style={healthBarStyle}>
          <div style={getHealthBarFillStyle(mousekingHealth)}>{mousekingHealth}%</div>
        </div>
  
        <h2>Door Sensor Status: {sensorState || 'Unknown'}</h2>
      </>
    );
  };
  
  export default Sc2HealthBar;
  