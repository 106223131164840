import React from "react";
import Sc2TugOfBattle from "./sc2_tugofbattle";
import Sc2TugOfWar from "./sc2_tugofwar";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col } from "react-bootstrap";
import { BiFontSize } from "react-icons/bi";
import { mk_red, nk_blue } from '../../styles/Sc2_color';

const Sc2PostContentMK = React.forwardRef(({ stats, ActiveTeam }, ref) => {
  return (
    <div className="post-content" ref={ref}>
      <div className="pc-p1">
        <img
          src="./images/nutcracker/nutcracker_single.png"
          crossorigin="anonymous"
          alt="Nutcracker"
          style={{
            maxWidth: "100%",
            minHeight: "800px",
            zIndex: "60",
            marginTop: "220px",
            right: "-50px",
            position: "absolute",
          }}
        />
        <div style={{marginTop:'0px', border:'0px solid #161822'}}>
          <h2 style={{textAlign:'left', fontSize:'1.6rem',
          fontStyle:'bold', padding:'10px', paddingTop:'20px', 
          paddingBottom:'40px', paddingLeft:'20px',
          borderLeft:`15px solid ${nk_blue}`
          }}>Report on the Showdown Between the Mouseking and the Nutcracker</h2>
          <Sc2TugOfWar
          fontsize="5rem"
          battlesWonMouseking={stats.battlesWonMouseking}
          battlesWonNutcracker={stats.battlesWonNutcracker}
          mousekingHealth={stats.mousekingHealth}
          nutcrackerHealth={stats.nutcrackerHealth}
          selectedTeam={ActiveTeam}
          stats={stats}
          dispheight='360px'
        />
         </div>
        
        <div>
        <h2 style={{textAlign:'left', paddingLeft:'20px', paddingTop:'20px', 
          borderLeft:`15px solid ${nk_blue}`,
          paddingBottom:'20px', fontSize:'1.2rem', padding:'10px'}}>Ongoing Battle</h2>
        <Sc2TugOfBattle
          mousekingHealth={stats.mousekingHealth}
          nutcrackerHealth={stats.nutcrackerHealth}
          selectedTeam={ActiveTeam}
          dispheight='220px'
        />
        </div>
      </div>
      <div className="pc-b-title" 
      style={{
        background: `linear-gradient(120deg, ${nk_blue} 20%, #262e3f 100%)` }}
      >
        <h2 style={{textAlign:'left'}}>I fought for the {ActiveTeam}</h2>
        <hr></hr>
        <p>@TheMuseum Kitchener<br></br>
        Currently we have {stats?.battlesWonMouseking ?? "N/A"} Battles won<br></br>
        Join our efforts and help us today!</p>
        <p style={{fontSize:'1rem', fontStyle:'italic', borderTop:'1px solid #D9CDBA', paddingTop:'20px'}}>
        10 King St W, Kitchener, ON N2G 1A3, Kanada
        </p>
        
        </div>
    </div>
  );
});

export default Sc2PostContentMK;
