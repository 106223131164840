import React, { useRef } from "react";
import Sc2PostContentMK from "../../components/Sc2/Sc2_PostContent_MK";
import SharePostButton from "../../components/Sc2/Sc2_sharePostButton";
import { useStats, battleScreenNCPercent, battleScreenMKPercent } from "../../functions/Sc2_homeAssistantStats";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col } from "react-bootstrap";
import Sc2TugOfBattle from "../../components/Sc2/sc2_tugofbattle";
import Sc2TugOfWar from "../../components/Sc2/sc2_tugofwar";

const ActiveTeam = "Mouseking";

const Sc2BattleScreen = () => {
  const stats = useStats(); // Fetch the stats
  const postRef = useRef(null);

  // Handle cases where stats are not yet loaded
  if (!stats) {
    return <div>Loading stats...</div>;
  }

   // Calculate percentages
   const nutcrackerPercent = battleScreenNCPercent(stats);
   const mousekingPercent = battleScreenMKPercent(stats);
 
   // Dynamic height calculation
   const mousekingHeight = `${200 + (mousekingPercent / 150) * 300}px`; // Minimum 200px, scales up to 500px
   const nutcrackerHeight = `${200 + (nutcrackerPercent / 150) * 300}px`; // Minimum 200px, scales up to 500px

  return (
    <div
      className="battle-screen"
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
        textAlign: "center",
      }}
    >
      {/* Images */}
      <img
        src="./images/mouseking/mouseking_single.png"
        alt="Mouseking"
        style={{
          maxWidth: "auto",
          height: mousekingHeight,
          position: "absolute",
          bottom: "0px",
          left: "30%",
          zIndex: 10,
        }}
      />
      <img
        src="./images/nutcracker/nutcracker_single.png"
        alt="Nutcracker"
        style={{
          maxWidth: "auto",
          height: nutcrackerHeight,
          position: "absolute",
          bottom: "0px",
          right: "30%",
          zIndex: 10,
        }}
      />

      {/* Battle Head Section */}
      <div className="battle-head" style={{ marginTop: "0px" }}>
        <Row>
          <Col>
            <h1>Battle Screen</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Mouseking</h2>
          </Col>
          <Col>
            <h2>vs</h2>
          </Col>
          <Col>
            <h2>Nutcracker</h2>
          </Col>
        </Row>
      </div>

      {/* Tug of War Section */}
      <div>
        <Row>
          <Col>
            <Sc2TugOfWar
            fontsize='3rem'
              battlesWonMouseking={stats.battlesWonMouseking}
              battlesWonNutcracker={stats.battlesWonNutcracker}
              mousekingHealth={stats.mousekingHealth}
              nutcrackerHealth={stats.nutcrackerHealth}
              selectedTeam={ActiveTeam}
              stats={stats}
              battleScreen={true} // Pass as a boolean
              dispheight="200px"
            />
          </Col>
        </Row>
      </div>

      {/* Current Status Section */}
      <div>
        <h3>The current status of the War</h3>
      </div>
      <Row>
        <Col>
          <Sc2TugOfBattle
            mousekingHealth={stats.mousekingHealth}
            nutcrackerHealth={stats.nutcrackerHealth}
            selectedTeam={ActiveTeam}
            dispheight="200px"
            battleScreen={true}
          />
        </Col>
      </Row>
      <div>
        <h3>The ongoing Battle</h3>
      </div>
    </div>
  );
};

export default Sc2BattleScreen;
