import { useEffect, useState } from 'react';

export const useHomeAssistantWebSocket = (homeAssistantUrl, authToken) => {
  const [connectionStatus, setConnectionStatus] = useState('Disconnected');
  const [sensorState, setSensorState] = useState(null);
  const [connection, setConnection] = useState(null);

  useEffect(() => {
    const ws = new WebSocket(homeAssistantUrl);

    ws.onopen = () => {
      console.log('WebSocket connection established');
      setConnectionStatus('Connected');
      setConnection(ws);  // Store the WebSocket connection
      
      // Send authentication message
      ws.send(JSON.stringify({
        type: 'auth',
        access_token: authToken
      }));
    };

    ws.onmessage = (message) => {
      const data = JSON.parse(message.data);
      console.log('Received:', data);

      if (data.type === 'auth_ok') {
        console.log('Authentication successful!');
        
        // Subscribe to state changes for entities
        ws.send(JSON.stringify({
          id: 2,
          type: 'subscribe_events',
          event_type: 'state_changed'
        }));

      } else if (data.type === 'auth_invalid') {
        console.log('Authentication failed:', data.message);
        ws.close();
      } else if (data.type === 'event' && data.event.event_type === 'state_changed') {
        const entity_id = data.event.data.entity_id;

        // Update sensor state if it's the door sensor
        if (entity_id === 'binary_sensor.aqara_doorsensor_contact') {
          const newState = data.event.data.new_state.state;
          setSensorState(newState);
          console.log('Door sensor state changed:', newState);
        }
      }
    };

    ws.onerror = (err) => {
      console.error('WebSocket error:', err);
      setConnectionStatus('Error');
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed');
      setConnectionStatus('Disconnected');
    };

    return () => {
      ws.close();
    };
  }, [homeAssistantUrl, authToken]);

  return { connectionStatus, sensorState, connection };
};
