import React, { useState } from "react";
import { useHomeAssistantWebSocket } from "../../useHomeAssistantWebSocket";
import { Link } from "react-router-dom";
import BtnXHome from "../../components/BtnXHome"; // Adjust path based on your file structure
import { FaCheese, FaUsers, FaSkullCrossbones } from "react-icons/fa"; // Font Awesome icons
import Sc2teamcircle from "../../components/Sc2/sc2_team_circle"; // Adjust path based on your file structure
import Tooltipbutton from "../../components/tooltipButton";
import Sc2TugOfBattle from "../../components/Sc2/sc2_tugofbattle"; // Adjust path based on your file structure

import '../../css/sc2_mobileview.css';

const Sc2MouseStatMobile = () => {
  const homeAssistantUrl = "wss://f1sd582v2u3wdospyl0cae1f1bjt2o05.ui.nabu.casa/api/websocket"; // Nabu Casa WebSocket URL
  const authToken = "your_auth_token"; // Replace with your actual token
  const { connectionStatus, sensorState, connection } =
    useHomeAssistantWebSocket(homeAssistantUrl, authToken);

  const [mousekingHealth, setMousekingHealth] = useState(100); // Mock data
  const [nutcrackerHealth, setNutcrackerHealth] = useState(80); // Mock data
  const [cheeseFed, setCheeseFed] = useState(500); // Mock data
  const [peopleInArmy, setPeopleInArmy] = useState(200); // Mock data
  const [battlesLost, setBattlesLost] = useState(3); // Mock data

  // HealthBar CSS
  const healthBarStyle = {
    width: "100%",
    backgroundColor: "#e0e0df",
    borderRadius: "5px",
    overflow: "hidden",
    margin: "20px 0",
  };

  const healthBarFillStyle = (value) => ({
    height: "30px",
    width: `${value}%`,
    backgroundColor: value > 50 ? "#76c7c0" : "#f76c6c",
    textAlign: "center",
    lineHeight: "30px",
    color: "white",
  });

  return (
    <div>
      <div className="mainwrapper">
        <div className="mobileheader">
          <div className="mobileheader-image">
          header
        </div>
        <div className-="mobilecontent">
          content here
        </div>
        <div className="mobilefooter">
          footer
        </div>
      </div>
    </div>
    </div>
  );
};

export default Sc2MouseStatMobile;
