import React, { useRef } from "react";
import { useStats } from "../../functions/Sc2_homeAssistantStats";
import Sc2PostContentNC from "../../components/Sc2/Sc2_PostContent_NC";
import SharePostButton from "../../components/Sc2/Sc2_sharePostButton";

const Sc2InstaPostMK = () => {
  const stats = useStats(); // Fetch the stats
  const postRef = useRef(null);

  // Handle cases where stats are not yet loaded
  if (!stats) {
    return <div>Loading stats...</div>;
  }

  return (
    <div>
      {/* Reusable Post Content */}
      <Sc2PostContentNC ActiveTeam="Nutcracker" stats={stats} ref={postRef} />

      {/* Share Post Button */}
      <SharePostButton
        
        contentRef={postRef}
        title="The Mouse King Army"
        text="Check out the latest stats for The Mouse King Army!"
      />
    </div>
  );
};

export default Sc2InstaPostMK;
