// src/styles/Sc2_color.js
export const mk_red = '#8d101c';
export const nk_blue = '#406caf';

    // Define gradients for each team
export const getHealthBarColor = (team) => {
 if (team === "Mouseking") {
     return "#161822"; // Single value for Mouseking
 } 
 if (team === "Nutcracker") {
     return "#C1B49F"; // Specific value for Nutcracker
 }
 return "#FCFAD9"; // Default value for other teams
};

export const getHealthBarGradient = (team) => {
if (team === "Mouseking") {
 return `linear-gradient(295deg, #8d101c, #f3e3d3)`; // Single value for Mouseking
} 
if (team === "Nutcracker") {
 return `linear-gradient(245deg, ${nk_blue}, #161822)`; // Single value for Mouseking
}
return "linear-gradient(245deg, #3b3b3b, #468b46)" // Intense to modest green
};

