export const getWarStatFeedback = (warStatNutcracker, warStatMouseking) => {
    // Variables to determine if a team is losing
    const LosingWarStatNutcracker = warStatNutcracker < 0;
    const LosingWarStatMouseking = warStatMouseking < 0;
  
    // Calculate absolute differences
    const nutcrackerDifference = Math.abs(warStatNutcracker);
    const mousekingDifference = Math.abs(warStatMouseking);
  
    // Messages for Nutcracker
    const nutcrackerWarMessage = LosingWarStatNutcracker
      ? `We are losing the war`
      : `We are winning the war`;
  
    // Messages for Mouseking
    const mousekingWarMessage = LosingWarStatMouseking
      ? `We are losing the war`
      : `We are winning the war`;
  
    // Return the feedback and values
    return {
      LosingWarStatNutcracker,
      LosingWarStatMouseking,
      nutcrackerWarMessage,
      mousekingWarMessage,
      nutcrackerDifference,
      mousekingDifference,
    };
  };
  