import React from 'react';
import { Link } from 'react-router-dom';
import '../style.css'; // Assuming your CSS is in style.css

const BtnXHome = () => {
  return (
    <div className="btn_x_home">
      <Link to="/" className="btn_x_link">X</Link>
    </div>
  );
};

export default BtnXHome;
