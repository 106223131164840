// src/utils/mousekingUtils.js

export const mousekingMessages = [
    "Retreat! The Nutcracker is too strong!",
    "Our forces falter! Rally the rats before we perish!",
    "The tide is against us, but the battle is not over yet!",
    "Strengthen the charge! The Nutcracker won't prevail!",
    "The soldiers waver—overwhelm them, my loyal mice!",
    "We taste victory! The Nutcracker trembles before us!",
    "The Nutcracker crumbles! Press onward, my minions!",
    "The Nutcracker falls! The Kingdom of Sweets will be ours!",
    "VICTORY",
  ];
  
  export const getMousekingMessage = (percentage) => {
    if (percentage <= 18) return mousekingMessages[0];
    if (percentage <= 25) return mousekingMessages[1];
    if (percentage <= 45) return mousekingMessages[2];
    if (percentage <= 55) return mousekingMessages[3];
    if (percentage <= 60) return mousekingMessages[4];
    if (percentage <= 70) return mousekingMessages[5];
    if (percentage <= 83) return mousekingMessages[6];
    if (percentage <= 100) return mousekingMessages[7];
    return mousekingMessages[7];
  };
  