import { createContext, useContext, useEffect, useState } from "react";
import { useHomeAssistantWebSocket } from "../useHomeAssistantWebSocket";

const StatsContext = createContext();

export const StatsProvider = ({ children, homeAssistantUrl, authToken }) => {
  const { connection } = useHomeAssistantWebSocket(homeAssistantUrl, authToken);

  const [stats, setStats] = useState({
    WarStatNutcracker: 0,
    HealthPotionFedNutcracker: 0,
    HealthPotionFedMouseking: 0,
    WarStatMouseking: 0,
    mousekingHealth: 100,
    nutcrackerHealth: 80,
    battlesWonNutcracker: 0,
    battlesWonMouseking: 0,
    damageDealtToNutcracker: 0,
    damageDealtToMouseking: 0,
    mousekingHealthChanged: false, // Add mousekingHealthChanged to state
  });

  const updateState = (entityId, newState) => {
    setStats((prevStats) => {
      const updatedStats = { ...prevStats };
      switch (entityId) {
        case "counter.canada_sc2_healthbar_mouseking":
          updatedStats.mousekingHealthChanged = prevStats.mousekingHealth !== newState;
          updatedStats.mousekingHealth = newState;
          break;
        case "counter.canada_sc2_healthbar_nutcracker":
          updatedStats.nutcrackerHealth = newState;
          break;
        case "counter.canada_sc2_battleswon_nutcracker":
          updatedStats.battlesWonNutcracker = newState;
          break;
        case "counter.canada_sc2_battleswon_mouseking":
          updatedStats.battlesWonMouseking = newState;
          break;
        case "counter.canada_sc2_damagedealt_to_nutcracker":
          updatedStats.damageDealtToNutcracker = newState;
          break;
        case "counter.canada_sc2_damagedealt_to_mouseking":
          updatedStats.damageDealtToMouseking = newState;
          break;
        case "counter.c anada_sc2_healthpotionfed_nutcracker":
          updatedStats.HealthPotionFedNutcracker = newState;
          break;
        case "counter.canada_sc2_healthpotionfed_mouseking":
        updatedStats.HealthPotionFedMouseking = newState;
        break;
        default:
          break;
      }

      // Update derived values
      updatedStats.WarStatNutcracker = updatedStats.battlesWonNutcracker - updatedStats.battlesWonMouseking;
      updatedStats.WarStatMouseking = updatedStats.battlesWonMouseking - updatedStats.battlesWonNutcracker;

      return updatedStats;
    });
  };

  useEffect(() => {
    if (connection) {
      console.log("WebSocket connection established");

      connection.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.type === "event" && message.event && message.event.data) {
          const { entity_id: entityId, new_state: { state } = {} } = message.event.data;
          const newState = parseInt(state, 10);
          if (entityId && !isNaN(newState)) {
            updateState(entityId, newState);
          }
        }

        if (message.id === 5 && message.result) {
          message.result.forEach((item) => {
            const newState = parseInt(item.state, 10);
            updateState(item.entity_id, newState);
          });
        }
      };

      connection.send(JSON.stringify({ id: 5, type: "get_states" }));
      connection.send(
        JSON.stringify({
          id: Date.now(),
          type: "subscribe_events",
          event_type: "state_changed",
        })
      );
    }
  }, [connection]);

  // Reset mousekingHealthChanged after 300ms whenever it changes
  useEffect(() => {
    if (stats.mousekingHealthChanged) {
      const timeout = setTimeout(() => {
        setStats((prevStats) => ({
          ...prevStats,
          mousekingHealthChanged: false,
        }));
      }, 300);

      return () => clearTimeout(timeout); // Cleanup
    }
  }, [stats.mousekingHealthChanged]);

  return <StatsContext.Provider value={stats}>{children}</StatsContext.Provider>;
};

export const useStats = () => useContext(StatsContext);



// Define percentages for Nutcracker and Mouseking
export const battleScreenNCPercent = (stats) => {
  const totalDifference = stats.battlesWonMouseking + stats.battlesWonNutcracker;
  return totalDifference === 0
    ? 50
    : (stats.battlesWonNutcracker / totalDifference) * 100;
};

export const battleScreenMKPercent = (stats) => {
  const totalDifference = stats.battlesWonMouseking + stats.battlesWonNutcracker;
  return totalDifference === 0
    ? 50
    : (stats.battlesWonMouseking / totalDifference) * 100;
};